<template>
  <div>
    <!--begin::Form-->
    <b-form class="form"
            @submit.stop.prevent="onSubmit">
      <div class="justify-content-center">
        <p class="font-size-h2 font-weight-bold mb-5">Giriş Yap</p>

        <b-form-group id="emailGroup">
          <b-form-input id="email"
                        name="email"
                        v-model="$v.form.email.$model"
                        :state="validateState('email')"
                        aria-describedby="email-input-live-feedback"
                        :readonly="this.readOnly"
                        placeholder="E-mail adresi">
          </b-form-input>

          <b-form-invalid-feedback id="email-input-live-feedback">
            Email zorunlu!
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group id="passwordGroup">
          <b-form-input id="password"
                        type="password"
                        name="password"
                        v-model="$v.form.password.$model"
                        :state="validateState('password')"
                        aria-describedby="password-input-live-feedback"
                        :readonly="this.readOnly"
                        placeholder="Şifre">
          </b-form-input>
          <b-form-invalid-feedback id="password-input-live-feedback">
            Şifre zorunlu!
          </b-form-invalid-feedback>
        </b-form-group>

        <b-button variant="outline-primary"
                  class="px-8 w-100"
                  ref="submitButton"
                  type="submit"
                  :disabled="this.readOnly"
                  @click="onSubmit">Giriş
        </b-button>

        <div class="text-right mt-3">
          <a href="forgot-password">Şifremi Unuttum</a>
        </div>
      </div>
    </b-form>
    <!--end::Form-->
  </div>
</template>

<script>
import { mapState } from "vuex";
import ApiService from "@/core/services/api.service";
import { LOGIN, LOGOUT } from "@/core/services/store/auth.module";
import JwtService from "@/core/services/jwt.service";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";

export default {
  name: "Login",
  mixins: [validationMixin],
  data() {
    return {
      readOnly: false,
      form: {
        email: "",
        password: "",
      },
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      this.readOnly = true;
      this.submitButton = this.$refs["submitButton"];
      this.$spinnerLoader.addSpinnerLoader(this.submitButton);

      // clear existing errors
      this.$store.dispatch(LOGOUT);
      this.login()
        .then((res) => {
          if (res.success == true && res.error == false) {
            this.$store.dispatch(LOGIN, res.data).then(() => {
              let currentRoute = JwtService.getCurrentRoute();
              if (currentRoute) {
                JwtService.destroyCurrentRoute();
                window.location = currentRoute;
              } else {
                this.$router.push("/");
              }
            });
          } else {
            this.$generateNotification(
              this,
              "error",
              "Bir hata oluştu, lütfen daha sonra tekrar deneyin."
            );
          }
        })
        .catch((err) => {
          if (err == "Error") {
            this.$generateNotification(
              this,
              "error",
              "Bir hata oluştu, lütfen daha sonra tekrar deneyin."
            );
          }
        })
        .finally(() => {
          this.readOnly = false;
          this.$spinnerLoader.removeSpinnerLoader(this.submitButton);
        });
    },
    login() {
      return new Promise((resolve, reject) => {
        ApiService.post("account/login", {
          Email: this.form.email,
          Password: this.form.password,
        })
          .then(({ data }) => {
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
  },
};
</script>

<style lang="scss">
.logo {
  width: 220px !important;
  margin-bottom: 20px !important;
}
.form-group {
  margin-bottom: 10px !important;
}
input {
  height: 50px !important;
}
.fs-12 {
  font-size: 12px;
}
</style>
